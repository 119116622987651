type loginUserType = {
  username: string;
  password: string;
  remember: boolean;
  returnUrl?: string | null;
};

type requestEmailType = {
  email: string;
}

type resetPWType = verifyEmailType & {
  password: string
}

type verifyEmailType = {
  token: string,
}


type registerUserType = {
  email: string,
  name: string,
  password: string,
  language: string,
  invitationToken?: string | null,
}

type ssoRequestLoginId = {
  entityId: string,
  returnUrl?: string
}

type methodType = "GET" | "POST";

export const loginUser = async (logindata: loginUserType) => {
  return sendRequest("auth/login", "POST", logindata);
};

export const loginAndAddAffiliations = async(token: string, logindata: loginUserType) => {
  return sendRequest(`signup/login?token=${token}`, "POST", logindata)
}

export const logoutUser = async () => {
  return sendRequest("auth/logout");
};

export const registerInvitedUser = async (registerData: registerUserType) => {
  return sendRequest("signup/register/token", "POST", registerData)
};

export const registerClickThroughUser = async (registerData: registerUserType) => {
  return sendRequest("signup/register/clickthrough", "POST", registerData)
};

export const getSSOList = async (unitId: string) => {
  let uri = 'sso/identities';
  if (unitId) {
    uri += `?unitId=${unitId}`
  }
  return sendRequest(uri);
};

export const sendSSORequest = async (loginId: ssoRequestLoginId) => {
  return sendRequest('sso/login', 'POST', loginId)
}

export const requestPassword = async (requestPassword: requestEmailType) => {
  return sendRequest("resetpassword/forgot-password", "POST", requestPassword)
};

export const requestPasswordReset = async (resetPassword: resetPWType) => {
  return sendRequest("resetpassword/reset-password", "POST", resetPassword)
};

export const requestVerificationEmail = async (requestEmail: requestEmailType) => {
  return sendRequest("userverification/send-verification-email", "POST", requestEmail)
}

export const getLanguages = async () => {
  return sendRequest("locale/languages")
}


export const verifyResetEmailToken = async (token: string) => {
  return sendRequest(`resetpassword/reset-password?token=${token}`)
}

export const verifyRegisterEmailToken = async (token: string) => {
  return sendRequest(`signup/invitation?token=${token}`)
}

export const verifyVerificationEmailToken = async (token: verifyEmailType) => {
  return sendRequest("userverification/verify", "POST", token)
}

//TODO: update type definition for data
const sendRequest = async (endpoint: string, method: methodType = "GET", data?: loginUserType | {}) => {
  const reqInit: RequestInit = {
    method,
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
    },
  };

  if (data) {
    reqInit.body = JSON.stringify(data);
  }

  const response = await fetch(`${endpoint}`, reqInit);

  return response;
};
