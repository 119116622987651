import React, { FC, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { IStore } from '../../store/types/store';
import { SSOIDState } from '../../store/types/auth';
import {
  Button,
  ButtonType,
  Select,
  selectOption,
  SelectType,
} from '@our/overbeck';
import { getSSOList, sendSSORequest } from '../../api';
import { setSSO } from '../../store/actions/sso';
import { useTranslation } from 'react-i18next';
import { useQuery } from '../utils';
// import { useHistory } from 'react-router-dom';

export const Sso: FC = () => {
  const { t } = useTranslation();
  const ssoList = useSelector<IStore, SSOIDState>((state) => state.sso);
  const [selectedSSO, setSelectedSSO] = useState<selectOption>(
    {} as selectOption
  );
  const query = useQuery();
  const dispatch = useDispatch();
  // const history = useHistory();

  const ssoSubmit: ButtonType = {
    name: 'sso',
    type: 'button',
    variant: 'primary',
    border_only: true,
    onClick: () =>
      sendSSORequest({
        entityId: selectedSSO.value,
        returnUrl: query.get('returnUrl') || undefined,
      })
        .then((data) => data.json())
        .then((data) => {
          if (data.spLoginUrl) {
            window.location = data.spLoginUrl;
          } else {
            // error case
          }
        }),
    label: t('Login.SSOButton'),
    cx: 'form--full-width',
  };

  const ssoSelect: SelectType = {
    name: 'Institution',
    label: t('Login.SSOInstitutions'),
    options: ssoList,
    onChange: (data: any) => setSelectedSSO(data.value),
  };

  useEffect(() => {
    const callForSSOList = () => {
      // shibbolethIdentities is a data structure returned from the API
      getSSOList(query.get('unitId') || '')
        .then((data) => data.json())
        .then((data) => dispatch(setSSO(data.shibbolethIdentities)));
    };
    callForSSOList();
  }, [dispatch]);

  return (
    <>
      <Select {...ssoSelect}></Select>
      <Button {...ssoSubmit} key={ssoSubmit.name}>
        {ssoSubmit.label}
      </Button>
    </>
  );
};
