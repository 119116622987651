import { SSO } from "../constants"
import { SSOIDState } from "../types/auth"
import { SSOSetAction, SSOGetAction } from "../types/action"


export const setSSO = (data: SSOIDState): SSOSetAction => ({
  type: SSO.set,
  payload: data
})

export const getSSO = (): SSOGetAction => ({
  type: SSO.get
})