import React, { FC } from 'react';
import './App.css';
import './components/form.scss';

import AuthRouter from './router/AuthRouter';
import { Header } from './components/header';
import { Footer } from './components/footer';
import { NotificationStore } from './Notifications/toastContext';

/**
 * Login Router
 *    Login / Sign in
 *    Register / Sign up
 *    Shibboleth
 *    Logout
 */

const App: FC = () => (
  <>
    <Header />
    <NotificationStore>
      <AuthRouter />
    </NotificationStore>
    <Footer />
  </>
);

export default App;
