import React from 'react';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';

import { Login } from '../components/Login/login';
import { Logout } from '../components/logout';
import { Register } from '../components/Register/register';
import { ForgotPassword } from '../components/Password/forgot';
import { Page404 } from '../components/NotFound/page404';
import { TokenInvalid } from '../components/Error/TokenInvalid';
import { TokenNotFound } from '../components/Error/TokenNotFound';
import { RegistrationError } from '../components/Error/RegistrationError';
import { ResetPassword } from '../components/Password/reset';
import { RequestVerification } from '../components/Email/requestVerification';
import { VerificationSent } from '../components/Email/verificationSent';
import { VerifyEmail } from '../components/Email/verifyEmail';
import { RegistrationDone } from '../components/Register/done';

const pathPrefix = process.env.REACT_APP_API_URL;

const AppRouter = () => (
  <Router basename={pathPrefix}>
    <main>
      <Switch>
        <Route path={`/:id/link-invalid`} component={TokenInvalid} />
        <Route path={`/:id/link-not-found`} component={TokenNotFound} />
        <Route path={`/:id/complete`} component={RegistrationDone} />
        <Route path={`/:id/error`} component={RegistrationError} />
        <Route path={`/:id/verification-sent`} component={VerificationSent} />

        <Route path={`/sign-in`} component={Login} />

        <Route path={`/sign-out`} component={Logout} />

        <Route path={`/register`} component={Register} exact />

        {/* forgot password routes */}
        <Route path={`/forgot-password`} component={ForgotPassword} />
        <Route path={`/reset-password`} component={ResetPassword} />

        {/* email verification routes */}
        <Route path={`/verification-request`} component={RequestVerification} />
        <Route path={`/verify-email`} component={VerifyEmail} />

        <Redirect to='/sign-in' />
      </Switch>
    </main>
  </Router>
);

export default AppRouter;
