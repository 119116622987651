import classNames from 'classnames';
import React, {
  CSSProperties,
  FC,
  ReactElement,
  useEffect,
  useRef,
} from 'react';

type ProgressType = {
  duration: number;
  removeMe: Function;
  isPaused: boolean;
};

export const ToastTimer: FC<ProgressType> = ({
  duration,
  removeMe,
  isPaused,
}): ReactElement => {
  const progressRef = useRef<HTMLDivElement>(null);

  const animationStyles: CSSProperties = {
    animationDuration: duration + 'ms',
    animationPlayState: isPaused ? 'paused' : 'running',
  };

  useEffect(() => {
    const removeToaster = () => removeMe();
    progressRef.current?.addEventListener('animationend', removeToaster);
    return () => {
      progressRef.current?.removeEventListener('animationend', removeToaster);
    };
  }, [removeMe]);

  return (
    <div
      className='toast__timer'
      style={animationStyles}
      ref={progressRef}
    ></div>
  );
};
