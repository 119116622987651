import React, { FC, useContext, useEffect, useState } from 'react';
import queryString from 'query-string';
import { useDispatch } from 'react-redux';
import { login } from '../../store';

import {
  useForm,
  Input,
  Password,
  Button,
  InputType,
  PasswordType,
  ButtonType,
  SectionTitle,
  Paragraph,
  Checkbox,
  SmallText,
  CheckboxType,
} from '@our/overbeck';
import { loginAndAddAffiliations, loginUser } from '../../api';
import { Link, useHistory } from 'react-router-dom';
import { Sso } from './sso';
import { useTranslation } from 'react-i18next';
import { ObjectType } from '../../types';
import { useQuery } from '../utils';
import { ToastContext } from '../../Notifications/toastContext';
type LoginFormType = { username: string; password: string; remember: boolean };

export const Login: FC<{
  location?: { search: string };
  registerData?: { email: string; token: string };
}> = ({ registerData }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const query = useQuery();
  const { addToast } = useContext(ToastContext);
  const dispatch = useDispatch();
  const history = useHistory();

  const { t } = useTranslation();

  const initialValues: LoginFormType = {
    username: '',
    password: '',
    remember: false,
  };

  const username: InputType = {
    name: 'username',
    label: t('Login.BasicAuthUsername'),
    isRequired: true,
  };

  const password: PasswordType = {
    name: 'password',
    label: t('Login.BasicAuthPassword'),
    isRequired: true,
  };

  const submit: ButtonType = {
    name: 'submit',
    type: 'submit',
    variant: 'primary',
    label: t('Login.BasicAuthButton'),
    cx: 'form--pull-right',
  };

  const remember: CheckboxType = {
    name: 'remember',
    label: t('Login.BasicAuthRememberMe'),
  };

  const handleSubmit = async (e: ObjectType): Promise<boolean> => {
    let response = null;
    setIsSubmitting(true);

    let formData = {
      username: e.username,
      password: e.password,
      remember: e.remember,
      returnUrl: query.get('returnUrl'),
    };

    if (registerData) {
      response = await loginAndAddAffiliations(
        encodeURIComponent(registerData.token),
        formData
      );
    } else {
      response = await loginUser(formData);
    }

    if (response.status === 200) {
      // redirect to redirect URL
      response = await response.json();

      if ('verified' in response && !response.verified) {
        history.push({
          pathname: 'verify/verification-sent',
          state: { email: response.email },
        });
        return false;
      }

      addToast({ text: t('Success.Login'), duration: 5000 });

      // console.log(response);

      localStorage.setItem('rememberMe', e.remember?.toString() ?? 'false');

      if (response.jwtToken) {
        dispatch(
          login({
            token: response.jwtToken,
            expiry: response.refreshTokenExpires,
            returnUrl: response.returnUrl,
          })
        );
      }

      if (response.returnUrl) {
        const { url, query } = queryString.parseUrl(response.returnUrl);
        let params = { ...query };

        if (params && Object.keys(params).length > 0) {
          window.location.href = `${url}?${queryString.stringify(params, {
            strict: true,
          })}`;
        } else {
          window.location.href = url;
        }
      } else {
        const { REACT_APP_REDIRECT_URL: url } = process.env;

        // redirect to ouriginal dashboard
        window.location.href = `${url}`;
      }
    }

    if (response.status >= 400) {
      response = await response.json();
      addToast({ text: response.message, duration: 5000, type: 'error' });
    }

    setIsSubmitting(false);
    return true;
  };

  const forForm = useForm<LoginFormType>({
    initialValues,
  });

  const { values, errors, onChange, onBlur, onSubmit, register } = forForm;

  const checkInputs = () => {
    register('username', {
      required: t('Error.UsernameRequired'),
    });

    register('password', {
      required: t('Error.PasswordRequired'),
    });
  };

  useEffect(() => {
    checkInputs();
  }, [checkInputs]);

  useEffect(() => {
    if (registerData) {
      forForm.values.username = registerData.email;
    }
  }, [registerData]);

  return (
    <>
      <section className="form">
        {registerData ? (
          <>
            <SectionTitle cx="form-register-title">
              {t('Register.AffiliationTitle')}
            </SectionTitle>
            <span></span>
          </>
        ) : (
          <>
            <SectionTitle>{t('Login.Title')}</SectionTitle>
            <>
              <Paragraph>{t('Login.SSOSubtitle')}</Paragraph>
              <Sso />
              <Paragraph cx="form__separator">
                <span className="form__separator-text">
                  {t('Login.DividerText')}
                </span>
              </Paragraph>
              <Paragraph>{t('Login.BasicAuthSubtitle')}</Paragraph>
            </>
          </>
        )}
        <form onSubmit={onSubmit(handleSubmit)} noValidate={true}>
          <div className="form__row">
            <Input
              {...username}
              value={values.username}
              errorText={errors.username?.message}
              onChange={onChange}
              onBlur={onBlur}
              isDisabled={!!registerData}
            ></Input>
          </div>
          <div className="form__row">
            <Password
              {...password}
              value={values.password}
              errorText={errors.password?.message}
              onChange={onChange}
              onBlur={onBlur}
            ></Password>
          </div>
          <div className="form__row">
            <Checkbox
              {...remember}
              value={values.remember as boolean}
              onChange={onChange}
              onBlur={onBlur}
            >
              {{
                label: <>{remember.label}</>,
              }}
            </Checkbox>
          </div>
          <div className="form__col">
            <Button
              {...submit}
              key={submit.name}
              aria-label={submit.label}
              isDisabled={isSubmitting}
            >
              {submit.label}
            </Button>
          </div>
          <div className="form__col">
            <Link to="/forgot-password">
              <SmallText>{t('Login.ForgotPasswordLink')}</SmallText>
            </Link>
          </div>
          {!registerData && (
            <div className="form__col">
              <Link to="/register">
                <SmallText>{t('Login.MaybeRegisterIsBetter')}</SmallText>
              </Link>
            </div>
          )}
        </form>
      </section>
    </>
  );
};
