import React, {
  FC,
  ReactElement,
  useContext,
  useEffect,
  useState,
} from 'react';

import {
  Paragraph,
  SectionTitle,
  Input,
  InputType,
  useForm,
  Button,
  ButtonType,
  SmallText,
} from '@our/overbeck';
import { requestPassword } from '../../api';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ObjectType } from '../../types';
import { ToastContext } from '../../Notifications/toastContext';

export const ForgotPassword: FC = (): ReactElement => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { addToast } = useContext(ToastContext);
  const { t } = useTranslation();

  const initialValues = {
    email: '',
  };

  const email: InputType = {
    name: 'email',
    type: 'email',
    label: t('ForgotPW.Email'),
    isRequired: true,
  };

  const submit: ButtonType = {
    name: 'submit',
    type: 'submit',
    label: t('ForgotPW.Button'),
  };

  const handleSubmit = async (e: ObjectType): Promise<boolean> => {
    let response = null;
    setIsSubmitting(true);

    response = await requestPassword({
      email: e.email,
    });
    // do something with response

    if (response.status > 400) {
      addToast({
        text: t('Error.ResetPWEmailSent'),
        duration: 5000,
        type: 'error',
      });
    }

    setIsSubmitting(false);

    // do something after the response

    if (response.status === 200) {
      // history.push('/reset-password');
      addToast({
        text: t('Success.ResetPWEmailSent', { email: e.email }),
        duration: 5000,
      });
    }

    return true;
  };

  const { values, errors, onChange, onBlur, onSubmit, register } = useForm<{
    email: string;
  }>({
    initialValues,
  });

  useEffect(() => {
    register('email', {
      required: t('Error.EmailRequired'),
      isEmail: t('Error.EmailIncorrect'),
    });
  }, []);

  return (
    <>
      <section className='form'>
        <SectionTitle>{t('ForgotPW.Title')}</SectionTitle>
        <Paragraph>{t('ForgotPW.Subtitle')}</Paragraph>
        <form onSubmit={onSubmit(handleSubmit)} noValidate={true}>
          <Input
            {...email}
            value={values.email}
            errorText={errors.email?.message}
            onChange={onChange}
            onBlur={onBlur}
          ></Input>
          <div className='form__col'>
            <Button {...submit} key={submit.name} isDisabled={isSubmitting}>
              {submit.label}
            </Button>
          </div>
          <div className='form__col'>
            <Link to='/sign-in'>
              <SmallText>{t('Message.ReturnToLogin')}</SmallText>
            </Link>
          </div>
        </form>
      </section>
    </>
  );
};
