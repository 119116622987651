const AUTH = {
  login: '[AUTH] login',
  logout: '[AUTH] logout',
};

const SSO = {
  get: "[SSO] get identities",
  set: "[SSO] set identities"
}

export {
  AUTH,
  SSO
};
