import { SSOIDState } from "../types/auth"
import { SSOGetAction, SSOSetAction } from "../types/action"
import { SSO } from "../constants"



const initialState: SSOIDState = [];

export const ssoReducer = (state: SSOIDState = initialState, action: SSOGetAction | SSOSetAction) => {
  switch (action.type) {
    case SSO.set:
      return [...state, ...(action as SSOSetAction).payload];
    case SSO.get:
    default:
      return state;
  }
}