import React, { FC, useState } from "react";
import { logoutUser } from "../api";

export const Logout: FC = () => {
  const [creds, setCreds] = useState({});
  const [loading, setLoading] = useState(false);

  return (
    <>
      <button
        onClick={async () => {
          setLoading(true);
          const credVal = await logoutUser();
          setCreds(credVal);
          setLoading(false);
        }}
      >
        Login Test User
      </button>
      {loading && <p>The user is being logged in</p>}
      {JSON.stringify(creds, null, 2)}
      <div>Login Page</div>
    </>
  );
};
