import i18n, { InitOptions } from "i18next";
import { initReactI18next } from "react-i18next";
import backend from "i18next-http-backend"
import { env } from "process";


const { REACT_APP_API_URL } = process.env
// const resources = {
//   en: {
//     translation: {
//       "Welcome to React": "Welcome to React and react-i18next"
//     }
//   }
// };
const options: InitOptions = {
  // resources,
  lng: "en",
  fallbackLng: "en",
  debug: true,

  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
  backend: {
    loadPath: `${REACT_APP_API_URL}/Locale/Strings`,
    allowMultiLoading: false,
    crossDomain: false
  }
}

i18n.use(backend).use(initReactI18next).init(options, (error, t) => {
  if (error) console.log(error)
  console.log(env)
});

export default i18n;
