import React, { ReactElement, FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  SectionTitle,
  Paragraph,
  PasswordType,
  useForm,
  Password,
  ButtonType,
  Button,
} from '@our/overbeck';
import { requestPasswordReset, verifyResetEmailToken } from '../../api';
import { useHistory } from 'react-router-dom';
import { ShowMessage } from '../Message/Message';
import { useQuery } from '../utils';
import { Loading } from '../loading';
const zxcvbn = require('zxcvbn');

type ResetPWType = {
  newpw: string;
  conpw: string;
};

export const ResetPassword: FC<{ location: { search: string } }> = (
  props
): ReactElement => {
  const [strength, setStrength] = useState<0 | 1 | 2 | 3 | 4>(0);
  const [isLoading, setLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errMsg, setErrMsg] = useState<string>('');
  const [resetToken, setResetToken] = useState<string>('');
  const [type, setType] = useState<'success' | 'fail' | 'form'>('form');
  const query = useQuery();
  const history = useHistory();
  const { t } = useTranslation();

  useEffect(() => {
    console.log('validating reset password link');
    const token = query.get('token');
    setIsSubmitting(true);
    if (token) {
      console.log(encodeURIComponent(token));
      setLoading(true);
      verifyResetEmailToken(encodeURIComponent(token)).then((res) => {
        setIsSubmitting(false);
        if (res.status >= 400) {
          history.push('reset/link-invalid');
        }

        if (res.status === 200) {
          setResetToken(token);
        }

        setLoading(false);
      });
    } else {
      setIsSubmitting(false);
      history.push('/reset/link-not-found');
    }

    setIsSubmitting(false);
  }, []);

  const newpw: PasswordType = {
    name: 'newpw',
    label: t('Reset.NewPassword'),
    isRequired: true,
  };

  const conpw: PasswordType = {
    name: 'conpw',
    label: t('Reset.ConfirmPassword'),
    isRequired: true,
  };

  const submit: ButtonType = {
    name: 'submit',
    type: 'submit',
    variant: 'primary',
    label: t('Reset.ResetPasswordButton'),
  };

  const initialValues: ResetPWType = {
    newpw: '',
    conpw: '',
  };

  const handleSubmit = async (values: ResetPWType): Promise<boolean> => {
    console.log('submitting new password');
    setIsSubmitting(true);

    // send new password here
    if (values.newpw && values.conpw && values.conpw !== values.newpw) {
      setErrMsg(t('Error.PasswordMismatch'));
    } else {
      setErrMsg('');
      const response = await requestPasswordReset({
        password: values.newpw,
        token: resetToken,
      });

      if (response.status === 200) {
        console.log('password reset successful');
        // redirect to dashboard or show password successfully reset
        setType('success');
      }

      if (response.status >= 400) {
        console.log('there was an issue reseting the password');
        setType('fail');
        // what type of error?
        const data: any = await response.json();
        console.log(data);
        if (data['errors']['Token'].length > 0) {
          history.push('/reset/link-invalid');
        } else {
          // redirect to generic error page
        }
      }
    }

    setIsSubmitting(false);

    return true;
  };

  const { values, errors, onChange, onBlur, onSubmit, register } = useForm<
    ResetPWType
  >({
    initialValues,
  });

  useEffect(() => {
    register('newpw', {
      required: t('Error.PasswordRequired'),
      validate: (data: any) => {
        const result = zxcvbn(data);
        setStrength(result['score'] || 0);
        console.log(strength, result['score']);
        if (result['score'] < 3) return t('Error.PasswordNotStrong');
      },
    });
  }, [register, strength, t]);

  useEffect(() => {
    register('conpw', {
      required: t('Error.PasswordConfirmationRequired'),
    });
  }, [register, t]);

  useEffect(() => {
    // Compare passwords when they are non-empty
    if (values.newpw && values.conpw && values.conpw !== values.newpw) {
      setErrMsg(t('Error.PasswordMismatch'));
    } else {
      setErrMsg('');
    }
  }, [values.newpw, values.conpw, t]);

  const PasswordResetForm = (
    <section className='form'>
      <SectionTitle>{t('Message.ResetPasswordTitle')}</SectionTitle>
      <Paragraph>{t('Message.ResetPasswordText')}</Paragraph>
      <form onSubmit={onSubmit(handleSubmit)} noValidate={true}>
        <div className='form__row'>
          <Password
            {...newpw}
            value={values.newpw}
            errorText={errors.newpw?.message}
            onChange={onChange}
            onBlur={onBlur}
            strength={strength}
          ></Password>
        </div>
        <div className='form__row'>
          <Password
            {...conpw}
            value={values.conpw}
            // errMsg is comparison error, it only happens when both passwords are non-empty and they dont match
            errorText={errMsg ? errMsg : errors.conpw?.message}
            onChange={onChange}
            onBlur={onBlur}
          ></Password>
        </div>
        <div className='form__col'>
          <Button
            {...submit}
            key={submit.name}
            aria-label={submit.label}
            isDisabled={isSubmitting}
          >
            {submit.label}
          </Button>
        </div>
      </form>
    </section>
  );

  return (
    <>
      {type === 'success' && (
        <ShowMessage
          from='reset'
          title='Password reset succeeded'
          text='We successfully reset your password!'
        />
      )}
      {type === 'fail' && (
        <ShowMessage
          from='reset'
          title='Password reset failed'
          text='we failed to reset the password due to unforsee circumstances'
        />
      )}
      {type === 'form' && isLoading && <Loading />}
      {type === 'form' && !isLoading && PasswordResetForm}
    </>
  );
};
