import { createStore, combineReducers } from "redux";
import { rootReducer } from "./reducers";

declare var process: any;
declare var require: any;

const combinedReducer = combineReducers(rootReducer)

const configureStore = () => {
  /* eslint-disable no-underscore-dangle,global-require */

  let store = null;
  if (process.env.NODE_ENV === "production") {
    store = createStore(combinedReducer);
  } else {
    const {
      composeWithDevTools,
    } = require("redux-devtools-extension/developmentOnly");
    store = createStore(
      combinedReducer, // For Redux Dev tools
      composeWithDevTools()
    );
  } /* eslint-enable */
  return store;
};

export const store = configureStore();
