import React, {
  FC,
  ReactElement,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  SectionTitle,
  Paragraph,
  InputType,
  ButtonType,
  useForm,
  Input,
  Button,
} from '@our/overbeck';
import { useTranslation } from 'react-i18next';
import { ObjectType } from '../../types';
import { requestVerificationEmail } from '../../api';
import { useHistory } from 'react-router-dom';
import { ToastContext } from '../../Notifications/toastContext';

export const RequestVerification: FC<{ location: { search: string } }> = (
  props
): ReactElement => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const history = useHistory();
  const { t } = useTranslation();

  const { addToast } = useContext(ToastContext);

  const initialValues = {
    email: '',
  };

  const email: InputType = {
    name: 'email',
    type: 'email',
    label: t('Verify.ToVerifyEmailInput'),
    isRequired: true,
  };

  const submit: ButtonType = {
    name: 'submit',
    type: 'submit',
    label: t('Verify.ToVerifyEmailButton'),
  };

  const { values, errors, onChange, onBlur, onSubmit, register } = useForm<{
    email: string;
  }>({
    initialValues,
  });

  const handleSubmit = async (e: ObjectType): Promise<boolean> => {
    console.log('submit request for verifying email');
    let response = null;
    setIsSubmitting(true);

    response = await requestVerificationEmail({
      email: e.email,
    });
    // do something with response

    if (response.status >= 400) {
      response = await response.json();
      addToast({ text: response.message, duration: 5000, type: 'error' });
    }

    if (response.status === 200) {
      addToast({
        text: t('Success.VerificationSent', { email: values.email }),
        duration: 5000,
      });
      setTimeout(() => {
        history.push({
          pathname: 'verify/verification-sent',
          state: { email: values.email },
        });
      }, 5001);
    }

    // do something after the response
    setIsSubmitting(false);
    return true;
  };

  useEffect(() => {
    register('email', {
      required: t('Error.EmailRequired'),
      isEmail: t('Error.EmailIncorrect'),
    });
  }, [register, t]);

  return (
    <>
      <section className='form'>
        <SectionTitle>{t('Verify.ToVerifyEmailTitle')}</SectionTitle>
        <Paragraph>{t('Verify.ToVerifyEmailText')}</Paragraph>
        <form onSubmit={onSubmit(handleSubmit)} noValidate={true}>
          <Input
            {...email}
            value={values.email}
            errorText={errors.email?.message}
            onChange={onChange}
            onBlur={onBlur}
          ></Input>
          <div className='form__col'>
            <Button {...submit} key={submit.name} isDisabled={isSubmitting}>
              {submit.label}
            </Button>
          </div>
        </form>
      </section>
    </>
  );
};
