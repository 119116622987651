import React, { ReactElement, FC } from "react";

import { ReactComponent as Logo } from "../assets/images/logo.svg";
export const Header: FC = (): ReactElement => {
  return (
    <header className="brand-logo">
      <h1 aria-label="Ouriginal">
        <Logo height="auto" />
      </h1>
    </header>
  );
};
