import React, { ReactElement, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { SectionTitle, Paragraph, Button, ButtonType } from '@our/overbeck';
// import { useParams } from 'react-router-dom';
export const RegistrationError: FC = (): ReactElement => {
  // const { id } = useParams<any>();
  const { t } = useTranslation();
  const submit: ButtonType = {
    name: 'button',
    type: 'button',
    variant: 'primary',
    label: t('Common.ContactUs'),
    cx: 'message__btn',
    onClick: () => {
      window.location.href = 'https://www.urkund.com/support/contact-support/';
    },
  };

  return (
    <>
      <section className='message'>
        <SectionTitle>{t('Error.RegistrationTitle')}</SectionTitle>
        <Paragraph cx='message__body'>{t('Error.RegistrationText')}</Paragraph>
        <Button {...submit} key={submit.name}>
          {submit.label}
        </Button>
        {/* <p>from where {id}</p> */}
      </section>
    </>
  );
};
