import React, { ReactElement, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { SectionTitle, Paragraph, Button, ButtonType } from '@our/overbeck';
import { useHistory } from 'react-router-dom';

export const VerificationSent: FC = (): ReactElement => {
  const { t } = useTranslation();
  const history = useHistory();

  const button: ButtonType = {
    name: 'resend',
    type: 'button',
    variant: 'secondary',
    label: t('Verify.VerificationEmailButton'),
    cx: 'form--pull-left',
    onClick: () => history.push('/verification-request'),
  };

  return (
    <>
      <section className='message'>
        <SectionTitle>{t('Verify.VerificationEmailTitle')}</SectionTitle>
        <Paragraph cx='message__body'>
          {t('Verify.VerificationEmailText')}
        </Paragraph>
        {/* {JSON.stringify(history.location.state, null, 2)} */}
        <Paragraph cx='message__body'>
          {t('Verify.VerificationEmailText2')}
        </Paragraph>
        <Button {...button}>{button.label}</Button>
      </section>
    </>
  );
};
