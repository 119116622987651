import React, { ReactElement, FC } from 'react';
import { useTranslation } from 'react-i18next';

export const Footer: FC = (): ReactElement => {
  const { t } = useTranslation();
  return (
    <footer className='footer'>
      <a href='https://www.ouriginal.com/our-support/' className='footer__link'>
        {t('Footer.Support')}
      </a>
      <a href='https://ouriginal.com/privacy-policy/' className='footer__link'>
        {t('Footer.Privacy')}
      </a>
      <a href='https://www.ouriginal.com' className='footer__link'>
        {t('Footer.Terms')}
      </a>
    </footer>
  );
};
