import React, { FC, ReactElement } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { Toast } from "./toast";

import "./toaster.scss";
import { InitialToastsType } from "./types";

export const Toaster: FC<{
  toasts: InitialToastsType;
  removeToast: (id: string) => void;
}> = ({ toasts, removeToast }): ReactElement => {
  return (
    <>
      {toasts.length > 0 && (
        <div className="toaster" role="dialog">
          <TransitionGroup className="toaster-list">
            {toasts.map((toast) => (
              <CSSTransition
                key={toast.id}
                timeout={{ enter: 500, exit: 500 }}
                classNames="toast-item"
              >
                <Toast
                  duration={toast.duration}
                  title={toast.title}
                  text={toast.text}
                  type={toast.type}
                  key={toast.id}
                  id={toast.id}
                  removeMe={() => removeToast(toast.id)}
                  cancellable={true}
                />
              </CSSTransition>
            ))}
          </TransitionGroup>
        </div>
      )}
    </>
  );
};
