import React, {
  FC,
  useEffect,
  useState,
  ReactElement,
  useContext,
  useReducer,
} from 'react';

import {
  Input,
  SelectType,
  Select,
  useForm,
  Checkbox,
  CheckboxType,
  PasswordType,
  Password,
  ButtonType,
  Button,
  InputType,
  selectOption,
  SectionTitle,
  Paragraph,
  SmallText,
} from '@our/overbeck';

import { useTranslation } from 'react-i18next';
import {
  getLanguages,
  verifyRegisterEmailToken,
  registerInvitedUser,
  registerClickThroughUser,
} from '../../api';
import { Link, useHistory } from 'react-router-dom';
import { useQuery } from '../utils';
import { ToastContext } from '../../Notifications/toastContext';
import { Login } from '../Login/login';

const zxcvbn = require('zxcvbn');

type LangType = {
  id: number;
  name: string;
};

type RegisterFormType = {
  name: string;
  email: string;
  password: string;
  language: selectOption;
  conditions: boolean;
};
// type RegisterErrorType = Partial<{ [key in keyof RegisterFormType]: string }>;

export const Register: FC<{ location: { search: string } }> = (
  props
): ReactElement => {
  const [strength, setStrength] = useState<0 | 1 | 2 | 3 | 4>(0);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [hasInvite, setHasInvite] = useState<boolean>(false);
  const { t } = useTranslation();
  const { addToast } = useContext(ToastContext);
  const query = useQuery();
  const history = useHistory();
  const [langs, setLangs] = useState<Array<selectOption>>([]);

  const [formType, setFormType] = useState<'register' | 'login'>('register');
  const [loginData, setLoginData] = useState<{
    email: string;
    token: string;
  }>({ email: '', token: '' });

  const reducer = (state: RegisterFormType, action: { payload: any }) => {
    return { ...state, ...action.payload };
  };

  const [state, dispatch] = useReducer<
    React.Reducer<RegisterFormType, { payload: any }>
  >(reducer, {
    name: '',
    email: '',
    password: '',
    language: langs[0],
    conditions: false,
  });

  useEffect(() => {
    const token = query.get('token');
    setIsSubmitting(true);

    if (token) {
      verifyRegisterEmailToken(encodeURIComponent(token)).then((res) => {
        setIsSubmitting(false);
        if (res.status >= 400) {
          history.push('/register/link-invalid');
        }

        if (res.status === 200) {
          setHasInvite(true);

          res.json().then((data) => {
            const { name, email, userAlreadyExists } = data;

            if (userAlreadyExists) {
              setFormType('login');
              setLoginData({ email, token });
            }

            dispatch({
              payload: {
                name,
                email,
              },
            });
          });
        }
      });
    } else {
      // user does not have token
      setIsSubmitting(false);
    }
  }, []);

  useEffect(() => {
    document
      .getElementsByName('email')?.[0]
      ?.setAttribute('autoComplete', 'username');
  });

  useEffect(() => {
    getLanguages()
      .then((data) => data.json())
      .then((data) =>
        data.map((datum: LangType) => ({ label: datum.name, value: datum.id }))
      )
      .then((data) => {
        setLangs(data);
        // Set default language here
        dispatch({
          payload: {
            language: data[0],
          },
        });
      });
  }, []);

  const name: InputType = {
    name: 'name',
    label: t('Register.Fullname'),
    helperText: t('Register.FullnameHelptext'),
    isRequired: true,
  };
  const email: InputType = {
    name: 'email',
    type: 'email',
    label: t('Register.Email'),
    helperText: t('Register.EmailHelptext'),
    isRequired: true,
    isDisabled: hasInvite,
  };
  const password: PasswordType = {
    name: 'password',
    label: t('Register.Password'),
    type: 'password',
  };

  const language: SelectType = {
    name: 'language',
    label: t('Register.Language'),
    options: langs,
    helperText: t('Register.LanguageHelptext'),
  };

  const conditions: CheckboxType = {
    name: 'conditions',
  };

  const buttons: Array<ButtonType> = [
    {
      name: 'submit',
      type: 'submit',
      variant: 'primary',
      onClick: () => console.log('button submit clicked'),
      label: t('Register.Button'),
    },
  ];

  // placeholder replace with call to backend
  const handleSubmit = async (values: RegisterFormType): Promise<boolean> => {
    let response = null;
    setIsSubmitting(true);

    if (hasInvite) {
      response = await registerInvitedUser({
        email: state.email,
        name: state.name,
        password: state.password,
        language: state.language.value,
        invitationToken: query.get('token'),
      });

      // 200 redirect to registration completed
      if (response.status === 200) {
        let { email, analysisAddress } = await response.json();
        history.push({
          pathname: 'register/complete',
          state: { email, analysisAddress },
        });
      }
    } else {
      response = await registerClickThroughUser({
        email: state.email,
        name: state.name,
        password: state.password,
        language: state.language.value,
      });

      // 200 redirect to verification email sent
      if (response.status === 200) {
        let { email } = await response.json();
        history.push({
          pathname: 'register/verification-sent',
          state: { email },
        });
      }
    }

    // 400 issue with registration
    if (response.status >= 400) {
      response = await response.json();
      addToast({
        text: response.message || response.title,
        duration: 50000,
        type: 'error',
      });
    }

    setIsSubmitting(false);
    return true;
  };

  const data = useForm<RegisterFormType>({
    initialValues: state,
  });
  const { errors, onChange, onBlur, onSubmit, register } = data;

  const checkName = () => {
    register('name', {
      required: t('Error.FullnameRequired'),
    });
  };
  const checkPW = () => {
    register('password', {
      required: t('Error.PasswordRequired'),
      validate: (data: any) => {
        const result = zxcvbn(data);
        setStrength((strength) => result['score'] || strength);
        if (result['score'] < 3) return t('Error.PasswordNotStrong');
      },
    });
  };
  const checkEmail = () => {
    register('email', {
      required: t('Error.EmailRequired'),
      isEmail: t('Error.EmailIncorrect'),
    });
  };
  const checkLang = () => {
    register('language', {
      required: t('Error.LanguageRequired'),
    });
  };
  const checkConditions = () => {
    register('conditions', {
      required: t('Error.ConditionsRequired'),
    });
  };
  useEffect(() => {
    checkName();
    checkPW();
    checkEmail();
    checkLang();
    checkConditions();
  }, []);

  useEffect(() => {
    if (hasInvite) {
      data.values.name = state.name;
      data.values.email = state.email;
    }
  }, [hasInvite, data, state]);

  const registerForm = () => {
    return (
      <section className="form">
        <SectionTitle>{t('Register.Title')}</SectionTitle>
        <Paragraph>{t('Register.Subtitle')}</Paragraph>
        <form
          onSubmit={onSubmit(handleSubmit)}
          noValidate={true}
          autoComplete={'off'}
        >
          <div className="form__row">
            <Input
              value={state.name}
              errorText={errors.name?.message}
              {...name}
              onChange={(e) => {
                onChange(e);
                dispatch({
                  payload: {
                    name: e.value,
                  },
                });
              }}
              onBlur={onBlur}
            />
          </div>
          <div className="form__row">
            <Input
              value={state.email}
              errorText={errors.email?.message}
              {...email}
              onChange={(e) => {
                onChange(e);
                dispatch({
                  payload: {
                    email: e.value,
                  },
                });
              }}
              onBlur={onBlur}
            />
          </div>
          <div className="form__row">
            <Password
              value={state.password}
              errorText={errors.password?.message}
              {...password}
              onChange={(e: any) => {
                onChange(e);
                dispatch({
                  payload: {
                    password: e.value,
                  },
                });
              }}
              onBlur={onBlur}
              strength={strength}
            />
          </div>
          <div className="form__row">
            <Select
              value={state.language}
              errorText={errors.language?.message}
              {...language}
              onChange={(e: any) => {
                onChange(e);
                dispatch({
                  payload: {
                    language: e.value,
                  },
                });
              }}
              onBlur={onBlur}
            />
          </div>
          <div className="form__row">
            <Checkbox {...conditions} onChange={onChange} onBlur={onBlur}>
              {{
                label: (
                  <>
                    {t('Register.TermsAndConditions1')}{' '}
                    <a href="https://www.urkund.com/about-us/privacy-policy/">
                      {t('Register.TermsAndConditions2')}
                    </a>{' '}
                    {t('Register.TermsAndConditions3')}
                  </>
                ),
                errorText: errors.conditions?.message,
              }}
            </Checkbox>
          </div>
          {buttons.map((button) => (
            <div className="form__col" key={button.name}>
              <Button {...button} key={button.name} isDisabled={isSubmitting}>
                {button.label}
              </Button>
            </div>
          ))}
          <div className="form__col">
            <Link to="/sign-in">
              <SmallText>{t('Register.MaybeSignInIsBetter')}</SmallText>
            </Link>
          </div>
          {/* <div> {JSON.stringify(data, null, 2)}</div>
          <div> {JSON.stringify(initialValues, null, 2)}</div> */}
        </form>
      </section>
    );
  };

  return (
    <>
      {formType === 'register' ? (
        registerForm()
      ) : (
        <Login registerData={loginData} />
      )}
    </>
  );
};
