import { InitialToastsType, GetActionType, SetActionType, RemoveActionType, ToastType, UIDType } from "./types";

export const GET_TOAST = '[TOAST] retreive from reducer';
export const SET_TOAST = '[TOAST] add to reducer';
export const REMOVE_TOAST = '[TOAST] remove from reducer';

const initialToasts: InitialToastsType = [];

export const toastsReducer = (
  state = initialToasts,
  { type, payload }: GetActionType | SetActionType | RemoveActionType
) => {
  switch (type) {
    case GET_TOAST:
      return state.filter((toast) => toast.id === (payload as UIDType).id);
    case SET_TOAST:
      return [
        ...state,
        { ...(payload as ToastType), id: Date.now().toString().substr(2) },
      ];
    case REMOVE_TOAST:
      return [...state.filter((toast) => toast.id !== (payload as UIDType).id)];
    default:
      return state;
  }
};