import { AUTH } from "../constants";
import { AuthState, AuthActionTypes, IAuthActionLogin, IAuthActionLogout } from "../types/auth";

export const authInitialState: AuthState = {
  token: null,
  expiry: null,
  returnUrl: null
};

export const authReducer = (state = authInitialState, action: AuthActionTypes): AuthState => {
  let typedAction;
  switch (action.type) {
    case AUTH.login:
      typedAction = action as IAuthActionLogin

      return {
        ...state,
        token: typedAction.payload.token,
        expiry: typedAction.payload.expiry,
        returnUrl: typedAction.payload.returnUrl
      };
    case AUTH.logout:
      typedAction = action as IAuthActionLogout

      return {
        ...state,
        token: null,
      };
    default:
      return state;
  }
};
