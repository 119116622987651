import React, { FC, ReactElement } from 'react';
import { SectionTitle, Paragraph } from '@our/overbeck';

import { MessagePageType } from '../../types';
export const ShowMessage: FC<MessagePageType> = ({
  text,
  title,
  from,
}): ReactElement => {
  return (
    <>
      <section className='message'>
        <SectionTitle>{title}</SectionTitle>
        <Paragraph cx='message__body'>{text}</Paragraph>
        {/* <p>from where {from}</p> */}
      </section>
    </>
  );
};
