import React, {
  FC,
  memo,
  ReactElement,
  useEffect,
  useRef,
  useState,
} from 'react';
import classnames from 'classnames';
import { useExpiry } from './useExpiry';
import { ToastComponentType } from './types';
import { ToastTimer } from './toastTimer';
import { IconCross } from '@our/overbeck';

export const Toast: FC<ToastComponentType & { id: string }> = memo(
  ({
    title,
    text,
    duration,
    type = 'success',
    removeMe,
    cancellable = true,
    id,
  }): ReactElement => {
    const toastRef = useRef<HTMLDivElement>(null);
    const [isPaused, setPaused] = useState(false);

    useEffect(() => {
      const pauseAnimation = () => setPaused(true);
      const runAnimation = () => setPaused(false);
      toastRef.current?.addEventListener('mouseover', pauseAnimation);
      toastRef.current?.addEventListener('mouseout', runAnimation);

      return () => {
        toastRef.current?.removeEventListener('mouseover', pauseAnimation);
        toastRef.current?.removeEventListener('mouseout', runAnimation);
      };
    }, []);

    return (
      <>
        <div
          className={classnames('toast', {
            'toast--success': type === 'success',
            'toast--warning': type === 'warning',
            'toast--error': type === 'error',
          })}
          ref={toastRef}
        >
          <ToastTimer
            duration={duration}
            removeMe={removeMe}
            isPaused={isPaused}
          />
          {cancellable && (
            <button className='toast__cancel' onClick={() => removeMe()}>
              <IconCross />
            </button>
          )}
          <div className='toast__data'>
            {title && <div className='toast__title'>{title}</div>}
            {text && <div className='toast__text'>{text}</div>}
          </div>
        </div>
      </>
    );
  },
);
