import React, { FC, ReactElement, useState, useEffect } from "react";
import { SectionTitle, Paragraph, Button } from "@our/overbeck";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { verifyVerificationEmailToken } from "../../api";
import { useQuery } from "../utils";

export const VerifyEmail: FC<{ location: { search: string } }> = (
  props
): ReactElement => {
  const [, setIsSubmitting] = useState(false);
  const query = useQuery();
  const { t } = useTranslation();
  const history = useHistory();

  useEffect(() => {
    const token = query.get("token");
    setIsSubmitting(true);

    if (token) {
      verifyVerificationEmailToken({
        token,
      }).then((res) => {
        setIsSubmitting(false);
        if (res.status >= 400) {
          history.push("/verify/link-invalid");
        }

        if (res.status === 200) {
          // TODO ... redirect to login?
        }
      });
    } else {
      history.push("/verify/link-not-found");
    }
  }, []);
  return (
    <>
      <section className="message">
        <SectionTitle>{t("Verify.VerificationCompleteTitle")}</SectionTitle>
        <Paragraph cx="message__body">
          {t("Verify.VerificationCompleteText")}
        </Paragraph>
        <Button
          variant="primary"
          isDisabled={false}
          onClick={() => history.push("/sign-in")}
        >
          Go to login
        </Button>
      </section>
    </>
  );
};
