import React, { ReactElement, FC, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { SectionTitle, Paragraph, Button, ButtonType } from '@our/overbeck';
import { useHistory } from 'react-router-dom';
export const RegistrationDone: FC = (): ReactElement => {
  const { t } = useTranslation();
  const history = useHistory();
  const { analysisAddress: email } = history.location.state as any;

  const submit: ButtonType = {
    name: 'button',
    type: 'button',
    variant: 'primary',
    label: t('Register.RegistrationCompletedButton'),
    cx: 'message__btn',
    onClick: () => {
      history.replace('/sign-in');
    },
  };

  return (
    <>
      <section className="message">
        <SectionTitle>{t('Register.RegistrationCompletedTitle')}</SectionTitle>
        <Paragraph cx="message__body">
          {t('Register.RegistrationCompletedText')}
        </Paragraph>
        {email && email !== '' && (
          <Paragraph cx="message__body">
            <Trans i18nKey="Register.RegistrationCompletedText2">
              You can now receive submissions at <strong>{{ email }}</strong>. This email is also
              available on your settings page.
            </Trans>
          </Paragraph>
        )}
        <div className="message__body--center">
          <Button {...submit} key={submit.name}>
            {submit.label}
          </Button>
        </div>
      </section>
    </>
  );
};
