import React, { createContext, ReactNode, useReducer } from 'react';
import { Toaster } from './toaster';
import { REMOVE_TOAST, SET_TOAST, toastsReducer } from './toastReducer';
import { InitialToastsType, ToastContextType, ToastType } from './types';

// export const useToast = () => {
//   const [toasts, dispatch] = useReducer(toastsReducer, []);

//   const addToast = (toast: ToastType) => {
//     dispatch({
//       type: SET_TOAST,
//       payload: toast,
//     });
//   };

//   const removeToast = (toastID: string) => {
//     dispatch({
//       type: REMOVE_TOAST,
//       payload: { id: toastID },
//     });
//   };

//   return {
//     toasts,
//     addToast,
//     removeToast,
//   };
// };

export const ToastContext = createContext<ToastContextType>(
  {} as ToastContextType
);

export const NotificationStore = ({ children }: { children: ReactNode }) => {
  const [toasts, dispatch] = useReducer(
    toastsReducer,
    [] as InitialToastsType | []
  );

  const addToast = (toast: ToastType) => {
    dispatch({
      type: SET_TOAST,
      payload: toast,
    });
  };

  const removeToast = (toastID: string) => {
    dispatch({
      type: REMOVE_TOAST,
      payload: { id: toastID },
    });
  };

  return (
    <ToastContext.Provider value={{ addToast, removeToast }}>
      {children}
      <Toaster toasts={toasts} removeToast={removeToast} />
    </ToastContext.Provider>
  );
};
