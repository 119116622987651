import { AUTH } from "../constants";
import { AuthState } from "../types/auth";

/**
 * Log the user in
 */
const login = (data: AuthState) =>
  <const>{
    type: AUTH.login,
    payload: data,
  };

/**
 * Log the user out
 */
const logout = () => <const>{ type: AUTH.logout };

export { login, logout };
