import React, { ReactElement, FC } from "react";
import { useTranslation } from "react-i18next";
import { SectionTitle, Paragraph, Button, ButtonType } from "@our/overbeck";
// import { useParams } from "react-router-dom";
export const TokenInvalid: FC = (): ReactElement => {
  // const { id } = useParams<any>();
  const { t } = useTranslation();
  const submit: ButtonType = {
    name: "button",
    type: "button",
    variant: "primary",
    label: t("Common.ContactUs"),
    cx: "message__btn",
    onClick: () => {
      window.location.href = "https://www.urkund.com/support/contact-support/";
    },
  };

  return (
    <>
      <section className="message">
        <SectionTitle>{t("Error.LinkInvalidTitle")}</SectionTitle>
        <Paragraph cx="message__body">{t("Error.LinkInvalidText")}</Paragraph>
        {/* <div className="form__col"> */}
        <Button {...submit} key={submit.name}>
          {submit.label}
        </Button>
        {/* </div> */}
        {/* <p>from where: {id}</p> */}
      </section>
    </>
  );
};

/**
 * TODO:
 * based on id create three conditional renderings as follows
 * invite: shows invalid invite link text with contact us button
 * verify: shows invalid verify link text with button redirect to verify-email
 * reset: shows invalid password reset link text with button to redirect user to reset-email
 */
